<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCard>
          <template v-slot:title>
            {{ operationTitle }}
          </template>
          <template v-slot:toolbar
                    v-if="$global.checkSuperUserCompanyProfile() && $global.hasPermission('owncompanyprofilesupdate')">
            <b-button class="mr-2 mt-3" @click="handleSubmitOperation"
                      size="sm"
                      variant="primary"
                      :disabled="global.pendingRequests > 0"
            >
              <i v-show="global.pendingRequests > 0"
                 class="fa fa-spin fa-spinner"></i>
              <i class="fa fa-save fa-sm"></i>
              Save
            </b-button>
          </template>

          <template v-slot:body>
            <div
              v-if="$global.checkSuperUserCompanyProfile() && $global.hasPermission('owncompanyprofilesupdate')"
              class="company-profiles-operation">
              <b-container fluid>
                <form @submit.prevent="handleSubmitOperation" autocomplete="off">
                  <b-row>
                    <b-col class="p-0" cols="12" md="12" lg="12" sm="12">
                      <b-row>
                        <b-col sm="6">
                          <b-form-group
                            label="Name Of Company *"
                            label-for="company_name"
                            :invalid-feedback="formErrors.first('company_name')"
                          >
                            <b-form-input
                              id="company_name"
                              v-model="formFields.company_name"
                              type="text"
                              :state="((formErrors.has('company_name') ? false : null))"
                            ></b-form-input>
                          </b-form-group>
                        </b-col><!--/b-col-->
                        <b-col sm="6">
                          <b-form-group
                            label="Company Owner *"
                            label-for="company_owner_name"
                            :invalid-feedback="formErrors.first('company_owner_name')"
                          >
                            <b-form-input
                              id="company_owner_name"
                              v-model="formFields.company_owner_name"
                              type="text"
                              :state="((formErrors.has('company_owner_name') ? false : null))"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <!--                                                <b-col sm="4">
                                                                            <b-form-group
                                                                                label="SuperUser *"
                                                                                label-for="user_id"
                                                                                :invalid-feedback="formErrors.first('user_id')"
                                                                            >
                                                                                <treeselect
                                                                                    id="user_id"
                                                                                    v-model="formFields.user_id"
                                                                                    type="text"
                                                                                    :options="dropdowns.superUsers"
                                                                                    :state="((formErrors.has('user_id') ? false : null))"
                                                                                />
                                                                            </b-form-group>
                                                                        </b-col>--><!--/b-col-->
                      </b-row><!--/b-row-->
                      <b-row>
                        <b-col sm="3">
                          <b-form-group
                            label="Email *"
                            label-for="email"
                            :invalid-feedback="formErrors.first('email')"
                          >
                            <b-form-input
                              id="email"
                              v-model="formFields.email"
                              type="email"
                              :state="((formErrors.has('email') ? false : null))"
                            ></b-form-input>
                          </b-form-group>
                        </b-col><!--/b-col-->
                        <b-col sm="3">
                          <b-form-group
                            label="Website *"
                            label-for="website"
                            :invalid-feedback="formErrors.first('website')"
                          >
                            <b-form-input
                              id="website"
                              v-model="formFields.website"
                              type="url"
                              :state="((formErrors.has('website') ? false : null))"
                            ></b-form-input>
                          </b-form-group>
                        </b-col><!--/b-col-->
                        <b-col sm="3">
                          <b-form-group
                            label="Instagram *"
                            label-for="instagram"
                            :invalid-feedback="formErrors.first('instagram')"
                          >
                            <b-form-input
                              id="instagram"
                              v-model="formFields.instagram"
                              type="url"
                              :state="((formErrors.has('instagram') ? false : null))"
                            ></b-form-input>
                          </b-form-group>
                        </b-col><!--/b-col-->
                        <b-col sm="3">
                          <b-form-group
                            label="Facebook *"
                            label-for="facebook"
                            :invalid-feedback="formErrors.first('facebook')"
                          >
                            <b-form-input
                              id="facebook"
                              v-model="formFields.facebook"
                              type="url"
                              :state="((formErrors.has('facebook') ? false : null))"
                            ></b-form-input>
                          </b-form-group>
                        </b-col><!--/b-col-->
                      </b-row>
                      <b-row>
                        <b-col sm="4">
                          <b-form-group
                            label="Country"
                            label-for="country_id"
                            :invalid-feedback="formErrors.first('country_id')"
                          >
                            <b-form-select
                              v-model="formFields.country_id"
                              :options="dropdowns.countries"
                              :state="((formErrors.has('country_id') ? false : null))"
                            >
                            </b-form-select>
                          </b-form-group>
                        </b-col><!--/b-col-->
                        <b-col sm="4">
                          <b-form-group
                            label="State"
                            label-for="state_id"
                            :invalid-feedback="formErrors.first('state_id')"
                          >
                            <b-form-select
                              v-model="formFields.state_id"
                              :options=" _.filter(dropdowns.states, (item) => item.country_id === formFields.country_id)"
                              :state="((formErrors.has('state_id') ? false : null))"
                            >
                            </b-form-select>
                          </b-form-group>
                        </b-col><!--/b-col-->
                        <b-col sm="4">
                          <b-form-group
                            label="City"
                            label-for="city"
                            :invalid-feedback="formErrors.first('city')"
                          >
                            <b-form-input
                              id="city"
                              v-model="formFields.city"
                              type="text"
                              :state="((formErrors.has('city') ? false : null))"
                            >
                            </b-form-input>
                          </b-form-group>
                        </b-col><!--/b-col-->
                      </b-row>
                      <b-row>
                        <b-col sm="4">
                          <b-form-group
                            label="Year Of Establishment *"
                            label-for="year_of_establishment"
                            :invalid-feedback="formErrors.first('year_of_establishment')"
                          >
                            <b-form-input
                              id="year_of_establishment"
                              v-model="formFields.year_of_establishment"
                              type="text"
                              :state="((formErrors.has('year_of_establishment') ? false : null))"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col sm="4">
                          <b-form-group
                            :disabled="true"
                            id="deactivation_date"
                            label="Activate Date *"
                            label-for="activation_date"
                            :invalid-feedback="formErrors.first('activation_date')"
                          >
                            <b-datepicker
                              :date-format-options="{ year: 'numeric',month: 'short', day: 'numeric' }"
                              id="activation_date"
                              v-model="formFields.activation_date"
                              type="text"
                              :state="((formErrors.has('activation_date') ? false : null))"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col sm="4">
                          <b-form-group
                            label="Deactivation Date"
                            label-for="deactivation_date"
                            :invalid-feedback="formErrors.first('deactivation_date')"
                          >
                            <b-datepicker
                              :date-format-options="{ year: 'numeric',month: 'short', day: 'numeric' }"
                              :disabled="true"
                              id="deactivation_date"
                              v-model="formFields.deactivation_date"
                              type="text"
                              :state="((formErrors.has('deactivation_date') ? false : null))"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="4">
                          <b-form-group
                            label="Bill Plan"
                            label-for="bill_plan"
                            :invalid-feedback="formErrors.first('bill_plan')"
                          >
                            <b-form-select
                              :disabled="true"
                              id="bill_plan"
                              :options="[{value:'Free',text:'Free'},{value:'Premium',text:'Premium'}]"
                              v-model="formFields.bill_plan"
                              type="text"
                              :state="((formErrors.has('bill_plan') ? false : null))"
                            ></b-form-select>
                          </b-form-group>
                        </b-col>
                        <b-col sm="4">
                          <b-form-group
                            label="PAN No"
                            label-for="pan_no"
                            :invalid-feedback="formErrors.first('pan_no')"
                          >
                            <b-form-input
                              id="pan_no"
                              v-model="formFields.pan_no"
                              type="text"
                              :state="((formErrors.has('pan_no') ? false : null))"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col sm="4">
                          <b-form-group
                            label="GST No"
                            label-for="gst_no"
                            :invalid-feedback="formErrors.first('gst_no')"
                          >
                            <b-form-input
                              id="gst_no"
                              v-model="formFields.gst_no"
                              type="text"
                              :state="((formErrors.has('gst_no') ? false : null))"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="4">
                          <b-form-group
                            label="No. of Employee"
                            label-for="no_of_employee"
                            :invalid-feedback="formErrors.first('no_of_employee')"
                          >
                            <b-form-input
                              id="no_of_employee" v-numericOnly.keyup
                              v-model="formFields.no_of_employee"
                              type="text"
                              :state="((formErrors.has('no_of_employee') ? false : null))"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col sm="4">
                          <b-form-group
                            label="No. of Properties"
                            label-for="no_of_properties"
                            :invalid-feedback="formErrors.first('no_of_properties')"
                          >
                            <b-form-input
                              :disabled="true"
                              id="no_of_properties"
                              v-model="formFields.no_of_properties"
                              type="text"
                              :state="((formErrors.has('no_of_properties') ? false : null))"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col sm="4">
                          <b-form-group
                            label="Auto delete bookings after x days"
                            label-for="auto_delete_bookings"
                            :invalid-feedback="formErrors.first('auto_delete_bookings')"
                          >
                            <b-form-input
                              id="no_of_properties"
                              v-model="formFields.auto_delete_bookings"
                              type="number"
                              :state="((formErrors.has('auto_delete_bookings') ? false : null))"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="12">
                          <b-form-group
                            label="Address"
                            label-for="address"
                            :invalid-feedback="formErrors.first('address')"
                          >
                            <b-form-textarea
                              id="address"
                              v-model="formFields.address"
                              type="text"
                            >
                            </b-form-textarea>
                            <b-form-invalid-feedback
                              :state="(!(formErrors.has('address')))">
                              {{ formErrors.first('address') }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <hr/>
                      <b-row class="operation-footer">
                        <b-col sm="12">
                          <b-button
                            size="sm"
                            type="submit"
                            variant="primary"
                            :disabled="global.pendingRequests > 0"
                          >
                            <i v-show="global.pendingRequests > 0"
                               class="fa fa-spin fa-spinner"></i>
                            <i class="fa fa-save fa-sm"></i>
                            Update
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-col><!--/b-col-->
                  </b-row><!--/b-row-->
                </form><!--/form-->
              </b-container><!--/b-container-->
            </div><!--/.company-profiles-operation-->
            <div v-else>
              <b-container fluid>
                <b-row>
                  <p class="text-danger">Be patient and wait, this form will activate soon once admin
                    give access.</p>
                </b-row>
              </b-container>
            </div>
          </template>
        </KTCard>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import KTCard from "@/view/content/Card.vue";
import Error from "@/core/services/Error";
import {request} from "@/core/services/Request";

const DEFAULT_FORM_STATE = {
  company_name: null,
  email: null,
  company_owner_name: null,
  address: null,
  user_id: undefined,
  country_id: undefined,
  state_id: undefined,
  city: null,
  website: null,
  facebook: null,
  instagram: null,
  year_of_establishment: null,
  pan_no: null,
  gst_no: null,
  activation_date: null,
  deactivation_date: null,
  no_of_employee: null,
  no_of_properties: null,
  bill_plan: undefined,
  _method: 'post',
};

export default {
  data() {
    return {
      operationTitle: 'View and Edit Company Profile',
      formFields: {...DEFAULT_FORM_STATE},
      formErrors: new Error({}),

      dropdowns: {
        superUsers: [],
        countries: [],
        states: [],
      },
    }
  },
  components: {
    KTCard
  },
  mounted() {
    this.getSuperUser()
    this.getCountry()
    this.getState()
    this.getDetail()
  },
  methods: {
    async handleSubmitOperation() {
      this.formErrors = new Error({})
      try {
        const response = await request({
          url: 'company/profiles/user/update',
          method: 'post',
          data: this.formFields,
        })
        this.$global.itemUpdated()
      } catch (error) {
        if (error.request && error.request.status && error.request.status === 422) {
          this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
          return false
        }
        this.$global.handleServerError(error)
      }

    },
    async getDetail() {
      try {
        const response = await request({
          url: '/company/profiles/user/detail',
          method: 'get',
        })

        const {data} = response.data

        this.formFields = {
          ...data,
          user_id: data.users ? data.users.id : undefined
        }
      } catch (e) {

      }
    },
    async getSuperUser() {
      try {
        const response = await request({
          url: '/dropdowns/super/user',
          method: 'post',
        })

        const {data} = response.data
        this.dropdowns.superUsers = data.map((item) => {
          return {
            id: item.id,
            label: item.name,
          }
        })
      } catch (e) {

      }
    },
    async getCountry() {
      try {
        const response = await request({
          url: '/dropdowns/countries',
          method: 'post',
        })

        const {data} = response.data
        this.dropdowns.countries = data.map((item) => {
          return {
            value: item.id,
            text: item.name,
          }
        })
      } catch (e) {

      }
    },
    async getState() {
      try {
        const response = await request({
          url: '/dropdowns/states',
          method: 'post',
        })

        const {data} = response.data
        this.dropdowns.states = data.map((item) => {
          return {
            value: item.id,
            text: item.name,
            country_id: item.country_id,
          }
        })
      } catch (e) {

      }
    },
  },
  computed: {
    ...mapState([
      'global',
    ]),
  },
}
</script>
